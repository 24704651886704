/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import phone from 'img/phone.svg'
import ButtonDefault from './ButtonDefault'

const StyledButtonPhone = styled(ButtonDefault)``

const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 15px;
`

const ButtonPhone = ({ isAnchor, isCustom, to, children, className }) => (
  <StyledButtonPhone isAnchor={isAnchor} isCustom={isCustom} to={to} className={className}>
    <Icon src={phone} alt="" />
    {children}
  </StyledButtonPhone>
)

export default ButtonPhone