/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'


const StyledTitleDefaultH1 = styled.h1`
  font-weight: ${props => props.theme.font.weight.l};
  font-family: ${props => props.theme.font.family.secondaryAlt};
  color: ${props => props.theme.color.text.grey};

  & div {
    font-weight: ${props => props.theme.font.weight.l};
    font-family: ${props => props.theme.font.family.secondaryAlt};
    color: ${props => props.theme.color.text.grey};
  }

  @media (min-width: 992px) {
    font-size: ${props => props.theme.font.size.xxxl};

    & div {
      font-size: ${props => props.theme.font.size.xxxl};
    }
  }

  @media (max-width: 991px){
    font-size: ${props => props.theme.font.size.xxl};

    & div {
      font-size: ${props => props.theme.font.size.xxl};
    }
  }
`

const StyledTitleDefaultH2 = styled.h2`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size.xxl};
  font-weight: ${props => props.theme.font.weight.l};
  color: ${props => props.theme.color.text.secondary};
  max-width: 340px;
  position: relative;

  ${props => props.stripe && css`
    &::after {
      background-color: #F39200;
      content: '';
      position: absolute;
      top: 59%;
      left: 106%;
      height: 2px;
      width: 833px;
    }
  `}
`

const TitleDefault = ({ stripe, className, children, h1 }) => (
  <>
    {h1 ? (
      <StyledTitleDefaultH1 className={`${className ? `${className}`: ``}`}>
        {children}
      </StyledTitleDefaultH1>
    ) : (
      <StyledTitleDefaultH2 stripe={stripe} className={`mx-3 ${className ? `${className}` : ``}`}>
        {children}
      </StyledTitleDefaultH2>
    )}
  </>
)


export default TitleDefault