/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import CookieConsent from 'react-cookie-consent'

// Theme
import Theme from 'styles/Theme'
import CustomLink from 'components/shared/CustomLink'

// Components
import Footer from './Footer'
import Header from './Header'

// CSS
import 'styles/all.scss'

const Layout = ({ children }) => (
  <>
    <Theme>
      <Header />
      <>{children}</>
      <Footer />
    </Theme>
    <CookieConsent
      location="bottom"
      style={{ backgroundColor: '#F39200', color: '#FFFFFF' }}
      buttonText="Akkoord"
    >
      <div className="px-lg-5">
        <div>
          We gebruiken cookies om ervoor te zorgen dat onze website zo soepel
          mogelijk draait. Als u doorgaat met het gebruiken van de website, gaan
          we er vanuit dat u ermee instemt.{' '}
          <CustomLink to="/privacyverklaring">
            Lees hier de privacyverklaring
          </CustomLink>
        </div>
      </div>
    </CookieConsent>
  </>
)

export default Layout
