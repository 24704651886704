/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Libraries
import {
  size as _size,
  concat as _concat,
  find as _find,
  each as _each,
  replace as _replace
} from 'lodash'

// Language
import { getLanguage, setLanguage } from 'services/language'

// Icons
import flagPL from 'img/Poland.svg'
import flagNL from 'img/netherlands.svg'

const Switch = styled.button`
  background-color: #e1e1e1;
`

const Icon = styled.img`
  width: 30px;
  height: 30px;
`

const LanguageSwitch = () => {
  const posts = { edges: [] }

  const {
    pages,
    // posts,
    site: { pathPrefix },
  } = useStaticQuery(graphql`
    {
      site {
        pathPrefix
      }
      pages: allWordpressPage {
        edges {
          node {
            path
            locale: wpml_current_locale
            translations: wpml_translations {
              locale
              path: href
            }
          }
        }
      }
      # posts: allWordpressPost {
      #   edges {
      #     node {
      #       path
      #       locale: wpml_current_locale
      #       translations: wpml_translations {
      #         locale
      #         path: href
      #       }
      #     }
      #   }
      # }
    }
  `)

  const language = getLanguage()

  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  if (pathPrefix) {
    pathname = _replace(pathname, pathPrefix, '')
  }

  const allPages = _concat(posts.edges, pages.edges)
  let toPage = null

  _each(allPages, ({ node }) => {
    if (node.path === pathname) {
      // eslint-disable-next-line prefer-destructuring
      toPage = node.translations[0]
    }

    if (_size(_find(node.translations, ({ path }) => path === pathname)) > 0) {
      toPage = node
    }
  })

  if (!toPage) {
    toPage = language === 'nl_NL' ? { path: '/pl/' } : { path: '/' }
  }

  const switchLanguage = () => {
    setLanguage(toPage)
  }

  return (
    <Switch
      type="button"
      className="language-switch"
      onClick={() => {
        switchLanguage(toPage)
      }}
    >
      {language === 'nl_NL' ? <Icon src={flagPL} alt="" /> : <Icon src={flagNL} alt="" />}
    </Switch>
  )
}

export default LanguageSwitch
