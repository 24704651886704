import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import CustomLink from 'components/shared/CustomLink'

const Socials = styled.div``

const Social = () => {
  const { social } = useStaticQuery(graphql`
    {
      social: allWordpressWpSocials(sort: { fields: id }) {
        edges {
          node {
            acf {
              url
              icon {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Socials className="col-lg-1 pl-0 d-flex justify-content-between">
      {social.edges.map(({ node: { acf } }, index) => (
        <a key={index} href={acf.url} target="_blank" rel="noreferrer">
          <img src={acf.icon.localFile.publicURL} />
        </a>
      ))}
    </Socials>
  )
}

export default Social
