import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    main: '#1D3660',
    contrast: '#F39200',
    secondary: '#F0F0F0',
    light: '#FFFFFF',
    grey: '#D8D8D8',
    soft: '#F0F0F0',
    border: '#CECECE',
    dark: '#3C3C3B',

    text: {
      main: '#707070',
      contrast: '#F39200',
      secondary: '#3C3C3B',
      light: '#FFFFFF',
      grey: '#D8D8D8',
      soft: '#E8E8E8',
      lightgrey: '#9C9C9C',
      dark: '#919191'
    },
  },

  font: {
    family: {
      main: 'Open Sans, sans-serif',
      secondary: 'aglet-sans, sans-serif',
      secondaryAlt: 'Neo Sans W01 Black',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xsm: '18px',
      xm: '20px',
      l: '24px',
      sl: '26px',
      ml: '28px',
      xl: '32px',
      xxl: '40px',
      xxxl: '65px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      sl: 700,
      xl: 800,
    },

    style: {
      italic: 'italic'
    }
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${props => props.theme.font.family.main};
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.main};
  }

  .color {
    &-background {
      &-main { background-color: ${props => props.theme.color.main} }
      &-contrast { background-color: ${props => props.theme.color.contrast} }
      &-secondary { background-color: ${props => props.theme.color.secondary} }
      &-light { background-color: ${props => props.theme.color.light} }
      &-soft { background-color: ${props => props.theme.color.soft} }
      &-dark { background-color: ${props => props.theme.color.dark} }
    }
    
    &-text {
      &-main { color: ${props => props.theme.color.text.main} }
      &-secondary { color: ${props => props.theme.color.text.secondary} }
      &-light { color: ${props => props.theme.color.text.light} }
      &-contrast { color: ${props => props.theme.color.text.contrast} }
      &-soft { color: ${props => props.theme.color.text.soft} }
      &-dark { color: ${props => props.theme.color.text.dark} }
    }
  }

  .font {
    &-family {
      &-main { font-family: ${props => props.theme.font.family.main} }
      &-secondary { font-family: ${props => props.theme.font.family.secondary} }
      &-secondary-alt { font-family: ${props => props.theme.font.family.secondaryAlt} }
    }

    &-size {
      &-xxs { font-size: ${props => props.theme.font.size.xxs} }
      &-xs { font-size: ${props => props.theme.font.size.xs} }
      &-s { font-size: ${props => props.theme.font.size.s} }
      &-sm { font-size: ${props => props.theme.font.size.sm} }
      &-m { font-size: ${props => props.theme.font.size.m} }
      &-xsm { font-size: ${props => props.theme.font.size.xsm} }
      &-xm { font-size: ${props => props.theme.font.size.xm} }
      &-l { font-size: ${props => props.theme.font.size.l} }
      &-ml { font-size: ${props => props.theme.font.size.ml} }
      &-xl { font-size: ${props => props.theme.font.size.xl} }
      &-xxl { font-size: ${props => props.theme.font.size.xxl} }
      &-xxxl { font-size: ${props => props.theme.font.size.xxxl} }
    }

    &-weight {
      &-s { font-weight: ${props => props.theme.font.weight.s} }
      &-m { font-weight: ${props => props.theme.font.weight.m} }
      &-l { font-weight: ${props => props.theme.font.weight.l} }
      &-xl { font-weight: ${props => props.theme.font.weight.xl} }
      &-italic-bold { font-weight: ${props => props.theme.font.weight.italicBold} }
    }

    &-style {
      &-italic { font-style: ${props => props.theme.font.style.italic} }
    }
  }
  
  .border {
    &-radius {
      &-lg { border-radius: 10px }
    }
  }
  
  .card {
    border: none !important;
    border-radius: unset !important;
    box-shadow: 0 0 2px 0 rgba(142, 142, 142, 0.35);
  }
  
  .orange-dot {
    color: ${(props) => props.theme.color.text.contrast};
  }
  
  @media (max-width: 1199.98px) {
  
  }
  
  @media (max-width: 991.98px) { 
  
  }
  
  @media (max-width: 767.98px) {
    h2 {
      font-size: ${(props) => props.theme.font.size.xl};
    }
    
    h3 {
      font-size: ${(props) => props.theme.font.size.l};
    }
  }
  
  @media (max-width: 575.98px) { 
  
  }
`

const Theme = ({ children }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Theme